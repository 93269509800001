
import {
  defineComponent,
  computed,
  onMounted,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import KTLoader from "@/components/Loader.vue";
import KTAside from "@/layout/aside/Aside.vue";
import KTHeader from "@/layout/header/Header.vue";
import KTFooter from "@/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
// import KTScrollTop from "@/layout/extras/ScrollTop.vue";
import { Actions } from "@/store/enums/StoreEnums";
import { MenuComponent } from "@/assets/ts/components/index";
import { removeModalBackdrop } from "@/core/helpers/dom";
import ViewTenantsModal from "@/components/modals/general/ViewTenantsModal.vue";

import {
  loaderEnabled,
  loaderLogo,
  asideEnabled,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

export default defineComponent({
  name: "OldLayout",
  inheritAttrs: false,
  components: {
    KTAside,
    KTHeader,
    KTFooter,
    ViewTenantsModal,
    // KTScrollTop,
    // KTUserMenu,
    // KTDrawerMessenger,
    KTLoader,
    // BaseToaster,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    // show page loading
    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return store.getters.pageTitle;
    });

    onMounted(() => {
      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        store.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
      () => route.path,
      () => {
        MenuComponent.hideDropdowns(undefined);

        // // check if current user is authenticated
        // if (store.getters.isAuthenticated) {
        //   router.push({ name: "sign-in" });
        // }

        removeModalBackdrop();
      }
    );

    return {
      loaderEnabled,
      loaderLogo,
      asideEnabled,
      pageTitle,
      themeLightLogo,
      themeDarkLogo,
    };
  },
});
